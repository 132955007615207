document.addEventListener("DOMContentLoaded", function (event) {
  const cookiesBlock = document.querySelector(".cookies-block");
  if (cookiesBlock !== null) {
    setTimeout(() => {
      gsap.to(cookiesBlock, {
        css: {
          opacity: 1,
          pointerEvents: "auto",
        },
      });
    }, 2000);
    const cookiesBlockBtn = cookiesBlock.querySelector(".cookies-block__ok");

    function closeCookiesBlock() {
      gsap.to(cookiesBlock, {
        css: {
          opacity: 0,
          pointerEvents: "none",
        },
      });
    }

    function requestCookie(e) {
      e.preventDefault();
      let token = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      fetch("/setCookies", {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
        },
        body: JSON.stringify({
          agree: true,
        }),
      }).then((response) => {
        closeCookiesBlock();
      });
    }

    cookiesBlockBtn.addEventListener("click", requestCookie);
  }

  setTimeout(() => {
    let tl = gsap.timeline();
    tl.to(document.querySelector(".pre-loader"), {
      duration: 0.1,
      css: {
        opacity: 0,
        pointerEvents: "none",
      },
    })
      .to(document.querySelector(".header"), {
        opacity: 1,
        duration: 0.7,
      })
      .to(document.querySelector(".content"), {
        opacity: 1,
        duration: 1,
      })
      .to(document.querySelector(".footer"), {
        opacity: 1,
        duration: 1,
      });
    if (localStorage.getItem("targetToScroll") !== "" && localStorage.getItem("targetToScroll") !== null) {
      let targetToScroll = localStorage.getItem("targetToScroll");
      let block = document.querySelector(targetToScroll);
      gsap.to(window, {
        duration: 2,
        delay: 1.2,
        scrollTo: block,
        ease: "power2",
      });
      localStorage.setItem("targetToScroll", "");
    }
  }, 200);

  const nav = document.querySelector(".nav");
  const openMenuBtn = document.querySelector(".open-menu");

  function openNav(e) {
    e.preventDefault();
    if (nav.classList.contains("nav_active")) {
      openMenuBtn.innerHTML = "меню";
      nav.classList.remove("nav_active");
      gsap.to(nav, {
        css: {
          opacity: 0,
          pointerEvents: "none",
        },
      });
      document.body.style.overflowY = "auto";
    } else {
      openMenuBtn.innerHTML = "закрыть";
      nav.classList.add("nav_active");
      gsap.to(nav, {
        css: {
          opacity: 1,
          pointerEvents: "auto",
        },
      });
      document.body.style.overflowY = "hidden";
    }
  }

  openMenuBtn.addEventListener("click", openNav);

  //Скролл наверх
  gsap.registerPlugin(ScrollToPlugin);
  const upBtn = document.querySelector(".up-btn");
  const header = document.querySelector("#header");

  function scrollPageToTop(e) {
    e.preventDefault();
    gsap.to(window, { duration: 1.5, scrollTo: header, ease: "power2" });
  }

  upBtn.addEventListener("click", scrollPageToTop);

  //Переход между страницами
  const allLinks = document.querySelectorAll("a");

  function redirect(href, out = false) {
    if (out) {
      window.open(href, "_blank");
    } else {
      window.location.href = href;
    }
  }

  function changePage(e) {
    e.preventDefault();
    let href = e.target.getAttribute("href");
    const currentHref = window.location;

    if (currentHref.pathname === href) {
      setTimeout(function () {
        window.location.reload();
      });
    } else {
      if (href !== "#") {
        if (href.startsWith("/#") && currentHref.pathname === "/") {
          let targetToScroll = href.substr(1, href.length);
          let block = document.querySelector(targetToScroll);
          gsap.to(window, {
            duration: 1.5,
            scrollTo: block,
            ease: "power2",
          });
          if (window.innerWidth < 999.98) {
            const nav = document.querySelector(".nav");
            nav.classList.remove("nav_active");
            document.querySelector(".open-menu").innerHTML = "меню";
            gsap.to(nav, {
              css: {
                opacity: 0,
                pointerEvents: "none",
              },
            });
          }
          document.body.style.overflowY = "auto";
        } else if (href.startsWith("/#")) {
          localStorage.setItem("targetToScroll", href.substr(1, href.length));
          href = href.split("#")[0];
          let tl = gsap.timeline();
          tl.to(document.body, {
            opacity: 0,
            duration: 0.3,
          }).call(redirect, [href]);
          setTimeout(() => {
            gsap.set(document.body, {
              opacity: 1,
            });
          }, 1000);
        } else if (!href.startsWith(currentHref.origin)) {
          redirect(href, true);
        } else {
          let tl = gsap.timeline();
          localStorage.setItem("targetToScroll", "");
          tl.to(document.body, {
            opacity: 0,
            duration: 0.3,
          }).call(redirect, [href]);
          setTimeout(() => {
            gsap.set(document.body, {
              opacity: 1,
            });
          }, 1000);
        }
      }
    }
  }

  allLinks.forEach((link) => {
    link.addEventListener("click", changePage);
  });
});
